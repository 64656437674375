import React from "react";
import { graphql } from "gatsby";
import Page from "../../components/page";
import ProductsListWithFilters from "../../containers/shop/ProductsListWithFilters";
import useTranslate from "../../hooks/use-translate";

const PodsPage = ({ data }: { data: Queries.PodsQueryDataQuery }) => {
  const t = useTranslate();
  return (
    <Page {...data.datoCmsWebPage} container="fluid">
      {/* PLP */}
      <ProductsListWithFilters
        products={data.allDatoCmsProduct.nodes}
        defaultTitle={t("shop.browseAllPods")}
      />
    </Page>
  );
};

export const query = graphql`
  query PodsQueryData($locale: String!) {
    datoCmsWebPage(path: { eq: "shop/pods" }, locale: { eq: $locale }) {
      ...WebPageFragment
    }
    allDatoCmsProduct(
      filter: {
        locale: { eq: $locale }
        productType: { eq: "oil" }
        disablePlp: { ne: true }
      }
      sort: { fields: position }
    ) {
      nodes {
        ...DatoCmsProductItemFragment
      }
    }
  }
`;

export default PodsPage;
